import * as React from 'react';
import { LoginProvider } from '@doltech/core/lib/auth/constants';
import { useAuthManager } from '@doltech/core/lib/auth/authManager';
import { AuthUserInfo } from '@doltech/core/lib/auth/interface/auth.interface';
import { DolAppEnum } from '@doltech/utils/lib/constants';
import { linkUser, purchasesApp } from '@doltech/core/lib/auth/api/registration';
import { useUserTracker } from '@doltech/core/lib/hooks/withCustomerIO';
import { useGTMTrackLogin } from '@doltech/core/lib/hooks/gtm/useGTMTrackLogin';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { useGlobalModalPortalStore } from '@doltech/ui/lib/hocs/withGlobalModalPortal';
import { ModalPortalLayoutCenterScrollable } from '@doltech/ui/lib/figma/Modal/ModalPortal/layouts/ModalPortalLayoutCenterScrollable';
import { ModalContentLayoutCenterCustomWidth } from '@doltech/ui/lib/figma/Modal/ModalPortal/layouts/ModalContentLayoutCenterCustomWidth';
import dynamic from 'next/dynamic';

const LogInModalContent = dynamic(
  () => import('../components/modal/LogInModalContent').then((m) => m.LogInModalContent),
  {
    ssr: false,
  }
);

const VerifyPhoneModalContent = dynamic(
  () =>
    import('../components/modal/VerifyPhoneModalContent').then((m) => m.VerifyPhoneModalContent),
  {
    ssr: false,
  }
);

export const useAuthorizeUser = (onSuccess?: (value: boolean) => void, onFailed?: () => void) => {
  const { show, hide } = useGlobalModalPortalStore();

  const authManager = useAuthManager();
  const [isBannedUser, setBannedUser] = React.useState(false);
  const [isSignedUp, setSignedUp] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState({} as AuthUserInfo);
  const { userLoggedIn } = useUserTracker();
  const { onUserSignedUp, onUserLoggedInSuccess } = useGTMTrackLogin();
  const { refetch } = useGetAuthInfo();
  const defaultUserState = React.useCallback(() => {
    setBannedUser(false);
  }, []);

  const onPurchaseApp = React.useCallback(async () => {
    if (!(await authManager.isPurchasesApp(DolAppEnum.DOLVN))) {
      await purchasesApp({
        app: DolAppEnum.DOLVN,
      });
      await linkUser();
      await authManager.refreshToken();
    }
  }, [authManager]);

  const onVerifyLoginSuccess = React.useCallback(async () => {
    await onPurchaseApp();
    userLoggedIn();
    onUserLoggedInSuccess();
    onSuccess?.(isSignedUp);
    refetch();
  }, [isSignedUp, onPurchaseApp, onSuccess, onUserLoggedInSuccess, refetch, userLoggedIn]);

  const onOpenVerifyModal = React.useCallback(() => {
    show({
      content: (
        <ModalPortalLayoutCenterScrollable
          className="verify-phone-portal-modal"
          onOverlayClick={hide}
        >
          <ModalContentLayoutCenterCustomWidth width={448}>
            <VerifyPhoneModalContent
              onVerifySuccess={async () => {
                authManager.saveLocalPhoneVerified(true);
                hide();
                onVerifyLoginSuccess();
              }}
              onHide={hide}
            />
          </ModalContentLayoutCenterCustomWidth>
        </ModalPortalLayoutCenterScrollable>
      ),
    });
  }, [show, hide, authManager, onVerifyLoginSuccess]);

  const onAfterLogin = React.useCallback(async () => {
    setUserInfo(await authManager.getUserInfo());
    authManager.saveHaveLoggedIn();
    const isBanned = await authManager.isBanned();
    setBannedUser(isBanned);
    if (isBanned) {
      await authManager.logout();
    } else {
      hide();
      const needVerify = await authManager.needVerifyPhone();
      if (needVerify) {
        authManager.clearAuthData();
        onOpenVerifyModal();
        setSignedUp(true);
        onUserSignedUp();
      } else {
        await onVerifyLoginSuccess();
        const { showToast } = await import('@doltech/ui/lib/figma/Toast');
        showToast({
          icon: 'success',
          title: 'Đăng nhập thành công',
        });
      }
    }
  }, [authManager, onUserSignedUp, onVerifyLoginSuccess, hide, onOpenVerifyModal]);

  const onOpenLoginModal = React.useCallback(
    ({ isDefaultLogIn }) => {
      show({
        content: (
          <ModalPortalLayoutCenterScrollable className="log-in-portal-modal" onOverlayClick={hide}>
            <ModalContentLayoutCenterCustomWidth width={448}>
              <LogInModalContent
                isDefaultLogIn={isDefaultLogIn}
                isBanned={isBannedUser}
                userInfo={userInfo}
                onLoginGoogle={() => {
                  defaultUserState();
                  authManager.login({ provider: LoginProvider.GOOGLE, afterLogin: onAfterLogin });
                }}
                onHide={hide}
              />
            </ModalContentLayoutCenterCustomWidth>
          </ModalPortalLayoutCenterScrollable>
        ),
      });
    },
    [show, hide, isBannedUser, userInfo, defaultUserState, authManager, onAfterLogin]
  );
  const onNeedToOpenLoginModal = React.useCallback(() => {
    show({
      content: (
        <ModalPortalLayoutCenterScrollable
          className="need-to-log-in-portal-modal"
          onOverlayClick={hide}
        >
          <ModalContentLayoutCenterCustomWidth width={448}>
            <LogInModalContent
              title="Bạn cần đăng ký tài khoản để lưu lại quá trình làm bài."
              subTitle="   "
              isBanned={isBannedUser}
              userInfo={userInfo}
              onLoginGoogle={() => {
                defaultUserState();
                authManager.login({ provider: LoginProvider.GOOGLE, afterLogin: onAfterLogin });
              }}
              onHide={hide}
            />
          </ModalContentLayoutCenterCustomWidth>
        </ModalPortalLayoutCenterScrollable>
      ),
    });
  }, [show, hide, isBannedUser, userInfo, defaultUserState, authManager, onAfterLogin]);

  return {
    onUserLogin: () => onOpenLoginModal({ isDefaultLogIn: true }),
    onUserSignUp: () => onOpenLoginModal({ isDefaultLogIn: false }),
    onNeedToAuthorizeUser: onNeedToOpenLoginModal,
  };
};
