import { css } from 'styled-components';
import { colorsV2 } from '../../colors-v2';

const colorNeutral = css`
  &.color-neutral {
    &.background-light {
      &.color-style-bold {
        background-color: ${colorsV2.neutral180};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.neutral160};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.neutral120};
        }

        &.disabled {
          background-color: ${colorsV2.neutral10};
          color: ${colorsV2.neutral60};
          cursor: not-allowed;
        }
      }

      &.color-style-matte {
        background-color: ${colorsV2.neutral10};
        color: ${colorsV2.neutral160};

        &:hover {
          background-color: ${colorsV2.neutral20};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.neutral40};
        }

        &.disabled {
          background-color: ${colorsV2.neutral10};
          color: ${colorsV2.neutral60};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        background-color: ${colorsV2.neutral180};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.neutral160};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.neutral120};
        }

        &.disabled {
          background-color: ${colorsV2.neutral20};
          color: ${colorsV2.neutral60};
          cursor: not-allowed;
        }
      }

      &.color-style-matte {
        background-color: ${colorsV2.neutral20};
        color: ${colorsV2.neutral160};

        &:hover {
          background-color: ${colorsV2.neutral40};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.neutral60};
        }

        &.disabled {
          background-color: ${colorsV2.neutral20};
          color: ${colorsV2.neutral60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        background-color: ${colorsV2.blackTransparent60};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.blackTransparent40};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.blackTransparent20};
        }

        &.disabled {
          background-color: ${colorsV2.blackTransparent10};
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }

      &.color-style-matte {
        background-color: ${colorsV2.blackTransparent20};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.blackTransparent40};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.blackTransparent60};
        }

        &.disabled {
          background-color: ${colorsV2.blackTransparent10};
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorGreen = css`
  &.color-green {
    &.background-light {
      &.color-style-matte {
        background-color: ${colorsV2.green10};
        color: ${colorsV2.green100};

        &:hover {
          background-color: ${colorsV2.green20};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.green40};
        }

        &.disabled {
          background-color: ${colorsV2.neutral10};
          color: ${colorsV2.neutral60};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorWhite = css`
  &.color-white {
    &.background-light {
      &.color-style-bold {
        background-color: ${colorsV2.white100};
        color: ${colorsV2.gray160};

        &:hover {
          background-color: ${colorsV2.gray20};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.gray40};
        }

        &.disabled {
          background-color: ${colorsV2.gray10};
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        background-color: ${colorsV2.white100};
        color: ${colorsV2.gray160};

        &:hover {
          background-color: ${colorsV2.gray20};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.gray40};
        }

        &.disabled {
          background-color: ${colorsV2.gray20};
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        background-color: ${colorsV2.white10};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.white20};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.white30};
        }

        &.disabled {
          background-color: ${colorsV2.white5};
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorGray = css`
  &.color-gray {
    &.background-light {
      &.color-style-bold {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray160};

        &:hover {
          background-color: ${colorsV2.gray20};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.gray40};
        }

        &.disabled {
          background-color: ${colorsV2.gray10};
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        background-color: ${colorsV2.gray20};
        color: ${colorsV2.gray160};

        &:hover {
          background-color: ${colorsV2.gray40};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.gray60};
        }

        &.disabled {
          background-color: ${colorsV2.gray20};
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        background-color: ${colorsV2.bt20};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.bt40};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.bt60};
        }

        &.disabled {
          background-color: ${colorsV2.bt10};
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorBlue = css`
  &.color-blue {
    &.background-light {
      &.color-style-bold {
        background-color: ${colorsV2.blue100};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.blue120};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.blue140};
        }

        &.disabled {
          background-color: ${colorsV2.gray10};
          color: ${colorsV2.black20};
          cursor: not-allowed;
        }
      }

      &.color-style-matte {
        background-color: ${colorsV2.blue10};
        color: ${colorsV2.blue100};

        &:hover {
          background-color: ${colorsV2.blue20};
          color: ${colorsV2.blue100};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.blue40};
          color: ${colorsV2.blue100};
        }

        &.disabled {
          background-color: ${colorsV2.neutral10};
          color: ${colorsV2.neutral60};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        background-color: ${colorsV2.blue100};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.blue120};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.blue140};
        }

        &.disabled {
          background-color: ${colorsV2.gray20};
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        background-color: ${colorsV2.blue140};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.blue120};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.blue160};
        }

        &.disabled {
          background-color: ${colorsV2.bt10};
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorBlack = css`
  &.color-black {
    &.background-light {
      &.color-style-bold {
        background-color: ${colorsV2.gray180};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.gray160};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.gray120};
        }

        &.disabled {
          background-color: ${colorsV2.gray10};
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        background-color: ${colorsV2.gray180};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.gray160};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.gray120};
        }

        &.disabled {
          background-color: ${colorsV2.gray20};
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        background-color: ${colorsV2.bt60};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.bt40};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.bt20};
        }

        &.disabled {
          background-color: ${colorsV2.bt10};
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorPrimary = css`
  &.color-primary {
    &.background-light {
      &.color-style-bold {
        background-color: ${colorsV2.primary100};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.primary120};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.primary140};
        }

        &.disabled {
          background-color: ${colorsV2.gray10};
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }

      &.color-style-matte {
        background-color: ${colorsV2.primary10};
        color: ${colorsV2.primary100};

        &:hover {
          background-color: ${colorsV2.primary20};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.primary40};
        }

        &.disabled {
          background-color: ${colorsV2.gray10};
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        background-color: ${colorsV2.primary100};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.primary120};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.primary140};
        }

        &.disabled {
          background-color: ${colorsV2.gray20};
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        background-color: ${colorsV2.primary140};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.primary120};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.primary160};
        }

        &.disabled {
          background-color: ${colorsV2.bt10};
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;
const colorPurple = css`
  &.color-purple {
    &.background-light {
      &.color-style-bold {
        background-color: ${colorsV2.purple100};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.purple120};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.purple140};
        }

        &.disabled {
          background-color: ${colorsV2.gray10};
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }

      &.color-style-matte {
        background-color: ${colorsV2.purple10};
        color: ${colorsV2.purple100};

        &:hover {
          background-color: ${colorsV2.purple20};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.purple40};
        }

        &.disabled {
          background-color: ${colorsV2.gray10};
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        background-color: ${colorsV2.primary100};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.primary120};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.primary140};
        }

        &.disabled {
          background-color: ${colorsV2.gray20};
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        background-color: ${colorsV2.purple140};
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.purple120};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.purple160};
        }

        &.disabled {
          background-color: ${colorsV2.bt10};
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;


export const filledStyles = css`
  &.filled {
    ${colorPrimary}
    ${colorBlue}
    ${colorBlack}
    ${colorWhite}
    ${colorGray}
    ${colorGreen}
    ${colorNeutral}
    ${colorPurple}
  }
`;
