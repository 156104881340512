import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" d="M12 2.4c-5.249 0-9.503 3.978-9.503 8.886 0 2.792 1.377 5.284 3.53 6.912V21.6l3.243-1.8c.865.243 1.781.373 2.73.373 5.249 0 9.503-3.979 9.503-8.887S17.25 2.4 12 2.4zm1 11.917l-2.457-2.554-4.733 2.618L11 8.874l2.457 2.553L18.19 8.81 13 14.317z" /></svg>;
});
export const IconSystemV2BrandsLogoMessenger1 = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));