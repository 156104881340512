import { environment } from '@doltech/core/lib/environment/environment';
import { GrammarTemplateTypeEnum } from '../../shared/constants';

export const useGrammarMenu = () => {
  const topBarMenu = [
    {
      id: 'LANDING_GRAMMAR_THEORY',
      title: 'Lý thuyết Ngữ pháp',
      basePath: environment.REACT_APP_DOL_GRAMMAR_WEBSITE_HOST_NAME,
      pageType: GrammarTemplateTypeEnum.LANDING_GRAMMAR_THEORY,
    },
    {
      id: 'LANDING_GRAMMAR_EXERCISE',
      title: 'Bài tập Ngữ pháp',
      basePath: environment.REACT_APP_DOL_GRAMMAR_WEBSITE_HOST_NAME,
      pageType: GrammarTemplateTypeEnum.LANDING_GRAMMAR_EXERCISE,
    },
  ];

  return { topBarMenu };
};
