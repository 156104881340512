import * as React from 'react';
import styled from 'styled-components';
import { ButtonIconPrimaryStyles } from '@doltech/ui/lib/figma/Button/ButtonIconPrimaryStyles/ButtonIconPrimaryStyles';
import { ButtonIconPrimarySize } from '@doltech/ui/lib/figma/Button/ButtonIconPrimarySize/ButtonIconPrimarySize';
import { IconCourseSearch } from '@doltech/icons/IconCourseSearch';

const Main = styled.div.withConfig({
  componentId: 'SearchEngine_SearchMobileComponent_Main',
} as any)``;
export const GrammarSearchMobileComponent = ({ onClick, showBarSearch }) => {
  return (
    <React.Suspense>
      <Main>
        {!showBarSearch && (
          <ButtonIconPrimaryStyles
            color="white"
            buttonStyle="outline"
            content={
              <ButtonIconPrimarySize size="x-large" icon={<IconCourseSearch/>}/>
            }
            onClick={onClick}
          />
        )}
      </Main>
    </React.Suspense>
  );
};
