import * as React from 'react';
import styled from 'styled-components';
import { ResponsiveTypography } from '../../../../figma/Typography/v3/ResponsiveTypography';
import { DolAvatarImg } from '../../../../figma/Avatar/DolAvatarImg';
import cl from 'classnames';

const Info = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  grid-auto-rows: max-content;
  gap: 4px;

  &.row {
    grid-auto-flow: column;
    grid-auto-columns: max-content;
  }
`;

const Container = styled.div<{ $gap: number }>`
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  align-items: center;
  gap: ${({ $gap }) => $gap}px;

  .sub-text {
    word-break: break-all;
  }
`;

export interface UserCardGeneralProps {
  name?: string;
  avatarUrl?: string;
  subText?: string;
  avatarSize?: string;
  gap?: number;
  layoutStyle?: 'row' | 'column';
  variantText?: string;
  variantSubText?: string;
}

export const UserCardGeneral = (props: UserCardGeneralProps) => {
  const {
    variantText = 'medium/14-20',
    variantSubText = 'regular/12-20',
    layoutStyle = 'column',
    gap = 12,
    name,
    avatarUrl,
    subText,
    avatarSize = '36px',
  } = props;

  return (
    <Container $gap={gap} className={cl('user-card-general', layoutStyle)}>
      <DolAvatarImg size={avatarSize} round url={avatarUrl} name={name} textSizeRatio={1.75} />
      <Info className={cl('info', layoutStyle)}>
        <ResponsiveTypography.Paragraph
          ellipsis={{ tooltip: true }}
          variant={variantText}
          color="grey180"
          className="user-name"
        >
          {name}
        </ResponsiveTypography.Paragraph>
        {subText && (
          <ResponsiveTypography.Paragraph
            ellipsis={{ tooltip: true }}
            variant={variantSubText}
            color="grey120"
            className="sub-text"
          >
            {subText}
          </ResponsiveTypography.Paragraph>
        )}
      </Info>
    </Container>
  );
};
