import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

const Container = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  &.x-small {
    width: 16px;
    height: 16px;
    padding: 4px;
    font-size: 8px;
  }

  &.small {
    width: 24px;
    height: 24px;
    padding: 6px;
    font-size: 12px;
  }

  &.medium {
    width: 32px;
    height: 32px;
    padding: 8px;
    font-size: 16px;
  }

  &.large {
    width: 36px;
    height: 36px;
    padding: 8px;
    font-size: 20px;
  }

  &.x-large {
    width: 40px;
    height: 40px;
    padding: 10px;
    font-size: 24px;
  }

  &.double-x-large {
    width: 48px;
    height: 48px;
    padding: 12px;
    font-size: 24px;
  }
`;

export interface ButtonIconPrimarySizeProps {
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large' | 'double-x-large';
  icon?: React.ReactNode;
}

export const ButtonIconPrimarySize = (props: ButtonIconPrimarySizeProps) => {
  const { size = 'large', icon } = props;

  return <Container className={cl('button-icon-primary-size', size)}>{icon}</Container>;
};
