import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ $width?: number }>`
  width: 100%;
  max-width: ${(p) => `${p.$width}px`};
  margin: 0 auto;
`;

interface ModalContentLayoutCenterCustomWidthProps {
  width?: number;
  className?: string;
  children?: React.ReactNode;
}

export const ModalContentLayoutCenterCustomWidth = (
  props: ModalContentLayoutCenterCustomWidthProps
) => {
  const { width = 480, children, className } = props;

  return (
    <Container className={cl('modal-portal-layout-center-scrollable', className)} $width={width}>
      {children}
    </Container>
  );
};
