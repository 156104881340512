import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { filledStyles } from './filledStyles';
import { outlineStyles } from './outlineStyles';
import { transparentFilledStyles } from './transparentFilledStyles';
import { transparentOutlineStyles } from './transparentOutlineStyles';

const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  cursor: pointer;
  user-select: none;

  ${filledStyles}
  ${outlineStyles}
  ${transparentFilledStyles}
  ${transparentOutlineStyles}
`;

export interface ButtonIconPrimaryStylesProps
  extends Pick<React.HTMLAttributes<HTMLSpanElement>, 'onClick'> {
  buttonStyle?: 'filled' | 'outline' | 'transparent' | 'light' | 'on-dark';
  color?: 'primary' | 'blue' | 'black' | 'gray' | 'white' | 'green' | 'neutral' | 'purple';
  backgroundStyle?: 'light' | 'gray' | 'dark';
  colorStyle?: 'bold' | 'matte';
  transparent?: boolean;
  content?: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
}

export const ButtonIconPrimaryStyles = (props: ButtonIconPrimaryStylesProps) => {
  const {
    buttonStyle = 'filled',
    backgroundStyle = 'light',
    transparent = false,
    color,
    content,
    active = false,
    disabled = false,
    colorStyle = 'bold',
    onClick,
  } = props;

  return (
    <Container
      className={cl(
        'button-icon-primary-styles',
        buttonStyle,
        `color-${color}`,
        `background-${backgroundStyle}`,
        `color-style-${colorStyle}`,
        { active, disabled, transparent }
      )}
      onClick={(e) => !disabled && onClick?.(e)}
    >
      {content}
    </Container>
  );
};
