import { deviceBreakpoints } from '@doltech/ui/lib/hooks/useDeviceQuery.hook';
import { isNotEmpty } from '@doltech/utils/lib/text';
import { noop } from 'lodash';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import * as React from 'react';
import styled from 'styled-components';
import { landingRoutingUrls } from '@doltech/core/lib/routing/grammar/grammar-routing.config';

const ElasticSearchWithAutocompleteGrammar = dynamic<any>(
  () =>
    import('./elastic-search/ElasticSearchWithAutocompleteGrammar').then(
      (mod) => mod.ElasticSearchWithAutocompleteGrammar
    ),
  { ssr: false }
);

const RelativeDiv = styled.div.withConfig({
  componentId: 'SearchEngine_ElasticSearchWithAutocomplete_RelativeDiv',
} as any)`
  position: relative;
  @media ${deviceBreakpoints.allExceptMobile} {
    width: 100%;
  }
`;

export const GrammarSearchComponent = ({ initialValue = '', onClearSearch = noop }) => {
  const router = useRouter();
  const defaultValue = (router.query.search as string) || initialValue;

  const goToSearchPage = React.useCallback(
    (value) => {
      if (isNotEmpty(value)) {
        router.push({
          pathname: landingRoutingUrls.GRAMMAR_SEARCH_PATH,
          query: { search: value },
        });
      }
    },
    [router]
  );

  return (
    <RelativeDiv>
      <ElasticSearchWithAutocompleteGrammar
        key={defaultValue}
        onEnter={goToSearchPage}
        defaultValue={defaultValue}
      />
    </RelativeDiv>
  );
};
