import { useGTMTracking } from './useGTMTracking';
import React from 'react';

export const useGTMTrackLogin = () => {
  const { setVariable, trackEvent } = useGTMTracking();

  const onUserSignedUp = React.useCallback(() => {
    setVariable({
      is_signed_up: true,
    });
  }, [setVariable]);

  const onUserLoggedInSuccess = React.useCallback(() => {
    trackEvent('dol-user-logged-in');
  }, [trackEvent]);

  return { onUserSignedUp, onUserLoggedInSuccess };
};
