import { environment } from '@doltech/core/lib/environment/environment';
import { FooterData } from '@doltech/ui/lib/figma/Layout/Landing/Footer/SharedFooter.types';
import * as React from 'react';
import { getCorpWebsiteUrl } from '@doltech/utils/lib/url';

export const useFooterData = ({ pageLink }) => {
  const columnMain = {
    title: 'Tự học IELTS',
    metadata: [
      {
        value: (
          <>
            Một sản phẩm thuộc Học viện Tiếng Anh Tư Duy DOL English (IELTS Đình Lực) -{' '}
            <a rel="nofollow" className="my-link" href="https://www.dolenglish.vn/">
              www.dolenglish.vn
            </a>
          </>
        ),
      },
      {
        meta: 'Trụ sở',
        link: {
          link: 'https://g.page/dolenglish?share',
          text: 'Hẻm 458/14, đường 3/2, P12, Q10, TP.HCM',
          external: true,
          noFollow: true,
        },
        value: 'Hẻm 458/14, đường 3/2, P12, Q10, TP.HCM',
      },
      {
        meta: 'Hotline',
        value: '1800 96 96 39',
        link: {
          link: 'tel:+1800969639',
          text: '1800 96 96 39',
          external: true,
          noFollow: true,
        },
      },
      {
        meta: 'Inbox',
        value: 'm.me/dolenglish.ieltsdinhluc',
        link: {
          link: 'https://m.me/dolenglish.ieltsdinhluc',
          text: 'm.me/dolenglish.ieltsdinhluc',
          external: true,
          noFollow: true,
        },
      },
    ],

    footer: [
      {
        link: 'https://www.facebook.com/dolenglish.grammar',
        text: 'Facebook',
        external: true,
        noFollow: true,
        icon: 'facebook',
      },
      {
        link: 'https://www.youtube.com/channel/UCEhPLLt928jxgOGDtyzgV9g',
        text: 'Youtube',
        external: true,
        noFollow: true,
        icon: 'youtube',
      },
      {
        link: 'https://www.tiktok.com/@dolenglish',
        text: 'Tiktok',
        external: true,
        noFollow: true,
        icon: 'tiktok',
      },
    ],
  };

  const data: FooterData = React.useMemo(() => {
    return {
      columnLeft: {
        columns: [],
      },
      columnMiddle: {
        columns: [
          {
            title: 'VỀ DOL ENGLISH - IELTS ĐÌNH LỰC',
            content: [
              {
                link: getCorpWebsiteUrl('/gioi-thieu-linearthinking'),
                text: 'Linearthinking',
                external: true,
                noFollow: true,
              },
              {
                link: getCorpWebsiteUrl('/nen-tang-cong-nghe'),
                text: 'Nền tảng công nghệ',
                external: true,
                noFollow: true,
              },
              {
                link: getCorpWebsiteUrl('/linearteachers'),
                text: 'Đội ngũ giáo viên',
                external: true,
                noFollow: true,
              },
              {
                link: getCorpWebsiteUrl('/hall-of-fame'),
                text: 'Thành tích học viên',
                external: true,
                noFollow: true,
              },
              {
                link: getCorpWebsiteUrl('/khoa-hoc-ielts'),
                text: 'Khóa học tại DOL ',
                external: true,
                noFollow: true,
              },
              {
                link: 'https://www.topcv.vn/',
                text: 'Tạo CV và tìm việc miễn phí',
                external: true,
              },
            ].filter(Boolean),
          },
        ],
      },

      columnRight: {
        columns: [
          {
            title: 'DOL ECOSYSTEM',
            content: [
              {
                link: environment.SEO_PREFIX,
                text: 'DOL TỰ HỌC',
                external: true,
              },
              {
                link: 'https://www.dolenglish.vn/category/ielts-knowledge ',
                text: 'Kiến thức IELTS tổng hợp',
                external: true,
              },
              {
                link: 'https://superlms.dolenglish.vn/',
                text: 'DOL superLMS',
                external: true,
              },
              {
                link: 'https://tudien.dolenglish.vn/',
                text: 'DOL DICTIONARY',
                external: true,
              },
            ].filter(Boolean),
          },
        ],
      },
    };
  }, []);

  return {
    columnLeft: data?.columnLeft,
    columnRight: data?.columnRight,
    columnMiddle: data?.columnMiddle,
    columnMain,
  };
};
