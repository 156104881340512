import { observer } from 'mobx-react-lite';
import * as React from 'react';
import withHydrationOnDemand from '@doltech/core/lib/hooks/withDolHydrationOnDemand';
import styled from 'styled-components';
import { SharedFooterStylesV2 } from '@doltech/ui/lib/figma/Layout/Landing/Footer/SharedFooter.styles';
import { useLandingSharedDataStore } from '@doltech/ui/lib/figma/Layout/Landing/model/landing-shared-data.hook';
import { DolGrammarLogoFooter } from '@doltech/ui/lib/figma/DolLogo';
import { ColumnList } from '@doltech/ui/lib/figma/Layout/Landing/Footer/components/ColumnList';
import { useFooterData } from './hooks/useFooterData';
import { GrammarFooterColumnMain } from './components/footer/GrammarFooterColumnMain';
import dynamic from 'next/dynamic';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';

const Main = styled.footer.withConfig({
  componentId: 'SharedFooterDictionary_Main',
} as any)`
  ${SharedFooterStylesV2}

  .responsive-section {
    padding: 40px 16px 32px;
    width: revert;
    ${fromScreen(540)} {
      padding: 40px 32px 32px;
      width: revert;
    }
    ${fromScreen(1144)} {
      padding: 40px 0 32px;
      width: revert;
    }
  }
`;

const GrammarFooterCopyRight = dynamic<any>(
  () =>
    import('./components/footer/GrammarFooterCopyRight').then((mod) => mod.GrammarFooterCopyRight),
  { ssr: true }
);

export const GrammarLandingPageFooterLayoutBase = observer(
  ({ renderLogo = () => <DolGrammarLogoFooter/> }: any) => {
    const store = useLandingSharedDataStore();
    const pageLink = React.useMemo(() => {
      if (store) {
        return store.pageLink;
      }
      return undefined;
    }, [store]);

    const { columnMiddle, columnRight, columnMain } = useFooterData({ pageLink });
    return (
      <Main id="shared-footer" className="responsive-container">
        <div className="responsive-section">
          {renderLogo()}
          <div id="footer-three-column">
            <GrammarFooterColumnMain data={columnMain}/>
            <div className="column-middle-n-right">
              <ColumnList data={columnMiddle} className="column-middle"/>
              <ColumnList data={columnRight} className="column-right"/>
            </div>
          </div>
          <GrammarFooterCopyRight/>
        </div>
      </Main>
    );
  },
);

export const GrammarLandingPageFooterLayout = withHydrationOnDemand({ on: ['idle'] })(
  GrammarLandingPageFooterLayoutBase,
);
