import { environment } from '@doltech/core/lib/environment/environment';
import { isEmpty } from 'lodash';
import { GrammarUrlByTopicType } from '@doltech/domains/grammar/lib/shared/utils/constants';

export const getFinalUrlGrammar = (url) => {
  return `${environment.REACT_APP_DOL_GRAMMAR_WEBSITE_HOST_NAME}/${url}`;
};

export const findGrammarUrlByTemplateTypeId = (otherUrlInfo: any, templateTypeId: any) => {
  if (isEmpty(otherUrlInfo)) {
    return null;
  }
  return otherUrlInfo?.find(item => item?.templateTypeId === templateTypeId)?.url;
};

export const getExerciseOrTheoryByTopicId = (baseUrl, topicId) => {
  return `${baseUrl}?topic=${GrammarUrlByTopicType[topicId]}`;
};

export const getExerciseOrTheoryByTopicIdAndPage = (baseUrl, topicId, page) => {
  return `${baseUrl}?topic=${GrammarUrlByTopicType[topicId]}&page=${page}`;
};
