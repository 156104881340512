import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import {
  GrammarSearchComponent,
} from '../../../search-page/components/GrammarSearchComponent';
import { useFromScreens } from '@doltech/ui/lib/hooks/useDeviceQuery.hook';
import {
  GrammarSearchMobileComponent,
} from '../../../search-page/components/GrammarSearchMobileComponent';

const Container = styled.div`
  .input-grey-style-layout {
    border-radius: 40px;
    min-height: 0;
  }
`;

export interface GrammarLandingTopSearchProps {
  onSearch?: (searchValue: string) => void;
  onSearchIconClick?: () => void;
  showBarSearch?: boolean;
}

export const GrammarLandingTopSearch = observer((props: GrammarLandingTopSearchProps) => {
  const {showBarSearch, onSearchIconClick} = props;
  const [, from1128] = useFromScreens([0, 1128]);

  if (from1128) {
    return (
      <Container>
        <GrammarSearchComponent/>
      </Container>
    );
  }

  return (
    <Container className="search-bar-full">
      <GrammarSearchMobileComponent
        onClick={onSearchIconClick}
        showBarSearch={showBarSearch}
      />
    </Container>
  );
  // return (
  //   <Container>
  //     <InputGreyStyleSearchBar
  //       className="grammar-landing-top-search"
  //       placeholder="Search"
  //       searchOnChange={onSearch}
  //       onSearchClear={() => onSearch(null)}
  //     />
  //   </Container>
  // );
});
