import {
  FreeTestPromotionBanner
} from '../../../../figma/Layout/Landing/ui/FreeTestPromotionBanner/FreeTestPromotionBanner';
import {
  FreeTestPromotionBannerTemplate
} from '../../../../figma/Layout/Landing/ui/FreeTestPromotionBanner/FreeTestPromotionBannerTemplate';
import { IconSystemV2BrandsLogoMessenger1 } from '@doltech/icons/SystemV2/IconSystemV2BrandsLogoMessenger1';
import { IconSystemV2OtherPhoneCallFilled } from '@doltech/icons/SystemV2/IconSystemV2OtherPhoneCallFilled';
import { IconGoogleMaps1 } from '@doltech/icons/IconGoogleMaps1';
import { s3ToCDN } from '@doltech/utils/lib/url';
import * as React from 'react';
import { environment } from '@doltech/core/lib/environment/environment';

export const LandingPromotionFreeTestBanner = () => {
  return (
    <FreeTestPromotionBannerTemplate
      imageUrl={s3ToCDN(
        'https://gqefcpylonobj.vcdn.cloud/directus-upload/037557e4-77c9-4d7a-aec8-2ce9a7e933f3.png'
      )}
      renderContent={
        <FreeTestPromotionBanner
          title="Đăng ký test đầu vào IELTS miễn phí và nhận tư vấn"
          highlightText="miễn phí"
          infos={[
            {
              icon: <IconSystemV2BrandsLogoMessenger1 size={20} color="#2196F3"/>,
              text: 'Nhắn tin DOL qua Facebook',
              subText: 'Click để nhắn tin với DOL qua fanpage chính',
              link: 'https://m.me/dolenglish.ieltsdinhluc',
            },
            {
              icon: <IconSystemV2OtherPhoneCallFilled size={20} color="#00A66C"/>,
              text: 'Gọi điện liên hệ',
              subText: 'Liên hệ DOL qua hotline miễn phí: 1800 96 96 39',
              link: 'tel:1800 96 96 39',
            },
            {
              icon: <IconGoogleMaps1 size={20}/>,
              text: 'DOL có 15+ cơ sở tại TP.HCM, Hà Nội và Đà Nẵng',
              subText: 'Click để xem địa chỉ chi tiết',
              link: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/he-thong-co-so`,
            },
          ]}
        />
      }
    />
  )
};
