import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(({ title, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M21.937 20.491l-5.826-5.823a7.802 7.802 0 001.63-4.797 7.824 7.824 0 00-2.307-5.565A7.811 7.811 0 009.87 2c-2.101 0-4.08.821-5.564 2.306A7.81 7.81 0 002 9.871c0 2.102.821 4.08 2.306 5.565a7.807 7.807 0 005.564 2.306 7.806 7.806 0 004.793-1.626l5.826 5.82a.215.215 0 00.304 0l1.144-1.14a.215.215 0 000-.305zm-7.911-6.464a5.844 5.844 0 01-4.156 1.721 5.844 5.844 0 01-4.155-1.72A5.846 5.846 0 013.994 9.87c0-1.569.611-3.046 1.72-4.156a5.844 5.844 0 014.156-1.72 5.827 5.827 0 014.156 1.72 5.846 5.846 0 011.72 4.156 5.842 5.842 0 01-1.72 4.156z"
      />
    </svg>
  );
});
export const IconCourseSearch = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
