import { css } from 'styled-components';

import { innerBorder } from '@doltech/utils/lib/css-style';
import { colorsV2 } from '../../colors-v2';

const colorWhite = css`
  &.color-white {
    &.background-light {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.gray160};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.gray10}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray20};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.gray160};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.gray40}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray40};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.white100};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.white10}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.white20};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorGray = css`
  &.color-gray {
    &.background-light {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.gray120};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.gray20}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray20};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.gray120};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.gray40}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray40};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.white100};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.bt20}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.bt40};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorNeutral = css`
  &.color-neutral {
    &.background-light {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.neutral180};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.neutral120}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.neutral20};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.neutral40};
          cursor: not-allowed;
        }
      }

      &.color-style-matte {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.neutral120};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.neutral20}`)}
          background-color: transparent;
          color: ${colorsV2.neutral160};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.neutral20};
          color: ${colorsV2.neutral160};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.neutral40};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.gray120};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.gray40}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray40};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.white100};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.bt20}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.bt40};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorBlue = css`
  &.color-blue {
    &.background-light {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.blue100};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.blue40}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.blue10};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.blue100};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.blue40}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.blue20};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.blue100};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.blue140}`)}
          background-color: transparent;
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.blue160};
          color: ${colorsV2.white100};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorBlack = css`
  &.color-black {
    &.background-light {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.gray180};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.gray120}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray20};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.gray180};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.gray120}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray20};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder(`2px solid ${colorsV2.bt60}`)}
        background-color: transparent;
        color: ${colorsV2.white100};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.bt20}`)}
          background-color: transparent;
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.bt30};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorPrimary = css`
  &.color-primary {
    &.background-light {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.primary100};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.primary20}`)}
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.primary10};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.primary100};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.primary20}`)}
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.primary20};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.primary100};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.primary140}`)}
          background-color: transparent;
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.primary160};
          color: ${colorsV2.white100};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;
const colorPurple = css`
  &.color-purple {
    &.background-light {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.purple100};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.purple20}`)}
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.purple10};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.purple100};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.purple20}`)}
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.purple20};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder('none')}
        background-color: transparent;
        color: ${colorsV2.purple100};

        &:hover {
          ${innerBorder(`2px solid ${colorsV2.purple140}`)}
          background-color: transparent;
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.purple160};
          color: ${colorsV2.white100};
        }

        &.disabled {
          ${innerBorder('none')}
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

export const transparentOutlineStyles = css`
  &.transparent {
    &.outline {
      ${colorPrimary}
      ${colorBlue}
      ${colorBlack}
      ${colorWhite}
      ${colorGray}
      ${colorNeutral}
      ${colorPurple}
    }
  }
`;
