import * as React from 'react';
import { RowLink } from '@doltech/ui/lib/figma/Layout/Landing/Footer/RowLink/RowLink';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { FooterIconMapper } from '@doltech/ui/lib/figma/Layout/Landing/Footer/components/FooterIconMapper';
import { Link } from '@doltech/ui/lib/figma/Link';

export const GrammarFooterColumnMain = ({ data }: { data: any }) => {
  return (
    <div className="column column-main">
      <div className="header">
        <div className="header-content">
          {data.metadata.map((item, index) => {
            const { meta, value, link } = item;
            return (
              <ResponsiveTypography.Paragraph
                color="white100"
                key={meta + index}
                variant="regular/14-20"
              >
                {meta && (
                  <ResponsiveTypography.Text variant="bold/14-20">
                    {meta}:&nbsp;
                  </ResponsiveTypography.Text>
                )}
                {link ? (
                  <RowLink variant="regular/14-20" color="white100" prefetch={false} row={link} />
                ) : (
                  value
                )}
              </ResponsiveTypography.Paragraph>
            );
          })}
        </div>
      </div>

      <ResponsiveTypography.Paragraph color="white100" variant="Bold/14px | 20px" mb="8px">
        Theo dõi DOL tại
      </ResponsiveTypography.Paragraph>
      <nav className="footer">
        {data.footer.map((row, rowIndex) => {
          const Icon = FooterIconMapper[row.icon];
          return (
            <Link
              prefetch={false}
              className="btn btn-icon"
              rel="nofollow"
              key={rowIndex}
              href={row.link || '#'}
            >
              <Icon />
            </Link>
          );
        })}
      </nav>
    </div>
  );
};
