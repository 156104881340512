import * as React from 'react';
import styled from 'styled-components';

import { Link } from '@doltech/ui/lib/figma/Link';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';

interface GrammarMenuLinkProps {
  url: string;
  title: string;
  disabled?: boolean;
}

const Container = styled.div`
  background-color: ${colorsV2.white100};
  padding: 16px;
`;

export const GrammarMenuLink = (props: GrammarMenuLinkProps) => {
  const { url, title, disabled } = props;

  if (disabled) {
    return (
      <Container>
        <ResponsiveTypography.Paragraph variant="medium/14-20" color="gray180">
          {title}
        </ResponsiveTypography.Paragraph>
      </Container>
    );
  }

  return (
    <Link href={url}>
      <Container>
        <ResponsiveTypography.Paragraph variant="medium/14-20" color="gray180">
          {title}
        </ResponsiveTypography.Paragraph>
      </Container>
    </Link>
  );
};
