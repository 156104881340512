import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';

const MainContent = styled.div`
  display: contents;
  pointer-events: auto;
`;

const LeftContent = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
  overflow: auto;
  pointer-events: none;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colorsV2.neutralTransparent70};
`;

interface GrammarSideBarMenuLayoutProps {
  className?: string;
  children?: React.ReactNode;
  onOverlayClick?: () => void;
}

export const GrammarSideBarMenuLayout = (props: GrammarSideBarMenuLayoutProps) => {
  const { className, children, onOverlayClick } = props;

  return (
    <Container
      className={cl('modal-portal-layout-left-drawer', className)}
      onClick={onOverlayClick}
    >
      <LeftContent className="right-content">
        <MainContent onClick={(e) => e.stopPropagation()}>{children}</MainContent>
      </LeftContent>
    </Container>
  );
};
