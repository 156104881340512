import * as React from 'react';
import { useAuthManager } from '@doltech/core/lib/auth/authManager';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { useGlobalModalPortalStore } from '@doltech/ui/lib/hocs/withGlobalModalPortal';
import { ModalPortalLayoutCenterScrollable } from '@doltech/ui/lib/figma/Modal/ModalPortal/layouts/ModalPortalLayoutCenterScrollable';
import { ModalContentLayoutCenterCustomWidth } from '@doltech/ui/lib/figma/Modal/ModalPortal/layouts/ModalContentLayoutCenterCustomWidth';
import { useRouter } from 'next/router';

import dynamic from 'next/dynamic';

const LogOutModalContent = dynamic(
  () => import('../components/modal/LogOutModalContent').then((m) => m.LogOutModalContent),
  {
    ssr: false,
  }
);

const PATH_TO_REDIRECT_HOME_AFTER_LOGOUT = [
  '/static/my-profile',
  '/static/my-account',
  '/static/my-rewards',
];
export const useLogOutUser = () => {
  const { show, hide } = useGlobalModalPortalStore();
  const router = useRouter();
  const authManager = useAuthManager();
  const { userInfo, refetch, isLoading } = useGetAuthInfo();

  const user = React.useMemo(() => {
    return {
      avatar: {
        photoURL: userInfo.photoURL,
        src: userInfo.photoURL,
        alt: userInfo.displayName,
      },
    };
  }, [userInfo.displayName, userInfo.photoURL]);

  const handleSignOut = React.useCallback(async () => {
    if (PATH_TO_REDIRECT_HOME_AFTER_LOGOUT.includes(router.pathname)) {
      await authManager.logout(true);
      await refetch();
      return;
    }
    await authManager.logout();
    await refetch();
    router.reload();
  }, [router, authManager, refetch]);

  const onLogOutModal = React.useCallback(() => {
    show({
      content: (
        <ModalPortalLayoutCenterScrollable
          className="verify-phone-portal-modal"
          onOverlayClick={hide}
        >
          <ModalContentLayoutCenterCustomWidth width={448}>
            <LogOutModalContent
              onHide={hide}
              user={user}
              onOk={handleSignOut}
              isLoading={isLoading}
            />
          </ModalContentLayoutCenterCustomWidth>
        </ModalPortalLayoutCenterScrollable>
      ),
    });
  }, [show, hide, user, handleSignOut, isLoading]);

  return {
    onUserLogOut: onLogOutModal,
  };
};
