export const lmsRoutingUrls = {
  /** Data Entry **/
  COURSE_DATA_ENTRY_GRAMMAR_TOPIC_LISTING: '/data-entry/topic',

  // theory
  COURSE_DATA_ENTRY_GRAMMAR_THEORY_LISTING: '/data-entry/theory',
  COURSE_DATA_ENTRY_GRAMMAR_THEORY_CREATE: '/data-entry/theory/create',
  COURSE_DATA_ENTRY_GRAMMAR_THEORY_EDIT: '/data-entry/theory/[id]/edit',
  COURSE_DATA_ENTRY_GRAMMAR_THEORY_PREVIEW: '/data-entry/theory/[id]/preview',


  // exercise
  COURSE_DATA_ENTRY_GRAMMAR_EXERCISE_LISTING: '/data-entry/exercise',
  COURSE_DATA_ENTRY_GRAMMAR_EXERCISE_CREATE: '/data-entry/exercise/create',
  COURSE_DATA_ENTRY_GRAMMAR_EXERCISE_EDIT: '/data-entry/exercise/[id]/edit',
  COURSE_DATA_ENTRY_GRAMMAR_EXERCISE_PREVIEW: '/data-entry/exercise/[id]/preview',


  // author
  COURSE_DATA_ENTRY_GRAMMAR_AUTHOR_LISTING: '/data-entry/author',

  /** Marketer **/
  // home
  COURSE_MARKETER_GRAMMAR_HOME_SEO_INFO: '/marketer/home/seo-info',

  // theory
  COURSE_MARKETER_GRAMMAR_THEORY_LISTING: '/marketer/theory',
  COURSE_MARKETER_GRAMMAR_THEORY_SEO_INFO: '/marketer/theory/seo-info',
  COURSE_MARKETER_GRAMMAR_THEORY_SEO_INFO_BY_ID: '/marketer/theory/seo-info/[id]',

  // exercise
  COURSE_MARKETER_GRAMMAR_EXERCISE_LISTING: '/marketer/exercise',
  COURSE_MARKETER_GRAMMAR_EXERCISE_SEO_INFO: '/marketer/exercise/seo-info',
  COURSE_MARKETER_GRAMMAR_EXERCISE_SEO_INFO_BY_ID: '/marketer/exercise/seo-info/[id]',

  // author
  COURSE_MARKETER_GRAMMAR_AUTHOR_LISTING: '/marketer/author',
  COURSE_MARKETER_GRAMMAR_AUTHOR_SEO_INFO: '/marketer/author/seo-info',
  COURSE_MARKETER_GRAMMAR_AUTHOR_SEO_INFO_BY_ID: '/marketer/author/seo-info/[id]',

  COURSE_MARKETER_GRAMMAR_TEMP: '/marketer/temp',
  COURSE_MARKETER_GRAMMAR_GIOI_THIEU_SEO_INFO: '/marketer/gioi-thieu/seo-info',

};

export const landingRoutingUrls = {
  /** Landing page static **/
  GRAMMAR_SEARCH_PATH: '/static/search-result',
  GRAMMAR_STUDENT_VIEW_MY_ACCOUNT: '/static/my-account',
};
