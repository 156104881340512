import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" fillRule="evenodd" d="M2 5c0-.46.466-.833 1.042-.833h14.586c.576 0 1.042.373 1.042.833 0 .46-.466.833-1.042.833H3.042C2.466 5.833 2 5.46 2 5zm0 5c0-.46.466-.833 1.042-.833h14.586c.576 0 1.042.373 1.042.833 0 .46-.466.833-1.042.833H3.042C2.466 10.833 2 10.46 2 10zm1.042 4.167C2.466 14.167 2 14.54 2 15c0 .46.466.833 1.042.833h14.586c.576 0 1.042-.373 1.042-.833 0-.46-.466-.833-1.042-.833H3.042z" clipRule="evenodd" /></svg>;
});
export const IconSystemV2ContentMenuCenter = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));