import * as React from 'react';
import { ButtonStyles } from '@doltech/ui/lib/figma/Button/ButtonStyles/ButtonStyles';
import { ButtonSize } from '@doltech/ui/lib/figma/Button/ButtonSize/ButtonSize';
import { ButtonContentGeneral } from '@doltech/ui/lib/figma/Button/ButtonContentGeneral/ButtonContentGeneral';
import styled from 'styled-components';

const Container = styled.div``;

interface GrammarSignUpButtonProps {
  name?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const GrammarSignUpButton = (props: GrammarSignUpButtonProps) => {
  const { name, onClick, disabled } = props;

  return (
    <Container className="grammar-sign-up-button" onClick={onClick}>
      <ButtonStyles
        color="purple"
        colorStyle="bold"
        disabled={disabled}
        content={
          <ButtonSize
            size="large"
            content={<ButtonContentGeneral text={name}/>}
            verticalPadding
          />
        }
      />
    </Container>
  );
};
