import styled from 'styled-components';
import { LAYER } from '@doltech/utils/lib/constants';

export const DolLogoWrapper = styled.div.withConfig({
  componentId: 'DolLogo-Main',
} as any)`
  line-height: 0;
  position: relative;
  height: 36px;
  width: 198px;

  &.small {
    width: 70.336px;
    height: 16px;
  }
  .logo-for-pdf {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.old {
    height: 40px;
    width: 105px;
  }
  img {
    width: 100%;
    height: 100%;
  }
  &.tuhoc-logo {
    height: 36px;
    width: 108px;
  }
`;

export const DolFooterWrapper = styled.div.withConfig({
  componentId: 'DolFooterLogo-Main',
} as any)`
  line-height: 0;
  position: relative;
  width: 212.78px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
  }
  &.tuhoc-logo {
    position: relative;
    left: -6px;
    height: 40px;
    width: 122px;
  }
`;

export const DolFooterDictionaryWrapper = styled.div.withConfig({
  componentId: 'DolFooterLogo-Main',
} as any)`
  line-height: 0;
  position: relative;
  width: 194px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
  }
  &.dictionary-logo {
    height: 40px;
    width: 120px;
  }
`;

export const DolLogoDictionaryWrapper = styled.div.withConfig({
  componentId: 'DolLogoDictionaryWrapperMain',
} as any)`
  line-height: 0;
  position: relative;
  width: 157.37px;
  height: 32px;
  z-index: ${LAYER.LAYER1};
  &.dictionary-logo {
    height: 36px;
    width: 108px;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const DolFooterGrammarWrapper = styled.div.withConfig({
  componentId: 'DolFooterLogo-Main',
} as any)`
  line-height: 0;
  position: relative;
  width: 120px;
  height: 40px;
`;

export const DolLogoGrammarWrapper = styled.div.withConfig({
  componentId: 'DolLogoGrammarWrapperMain',
} as any)`
  line-height: 0;
  position: relative;
  width: 100%;
  height: 32px;
  z-index: ${LAYER.LAYER1};

  img {
    height: 100%;
  }
`;

export const DolLogoWithoutTextWrapper = styled.div.withConfig({
  componentId: 'DolLogoWithoutTextWrapper',
} as any)`
  line-height: 0;
  position: relative;
  width: 29px;
  height: 32px;
`;
