import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { GrammarMenuLink } from './header/GrammarMenuLink';
import { ButtonIconPrimarySize } from '@doltech/ui/lib/figma/Button/ButtonIconPrimarySize/ButtonIconPrimarySize';
import { ButtonIconPrimaryStyles } from '@doltech/ui/lib/figma/Button/ButtonIconPrimaryStyles/ButtonIconPrimaryStyles';
import { IconSystemV2EssentialClose } from '@doltech/icons/SystemV2/IconSystemV2EssentialClose';
import {
  GrammarSignUpButton,
} from '../components/header/GrammarSignUpButton';
import {
  GrammarSignInButton,
} from '../components/header/GrammarSignInButton';
import { useAuthorizeUser } from '../../home-page/hooks/useAuthorizeUser';
import { useLogOutUser } from '../../home-page/hooks/useLogOutUser';
import { horizontalSpace } from '@doltech/utils/lib/css-style';
import {
  UserAccountCardGrammar,
} from '../components/header/UserAccountCardGrammar';
import { IconSystemV2ArrowsExit } from '@doltech/icons/SystemV2/IconSystemV2ArrowsExit';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { observer } from 'mobx-react-lite';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { findGrammarUrlByTemplateTypeId, getFinalUrlGrammar } from '../../shared/utils';
import { GrammarTemplateTypeEnum } from '../../shared/constants';

const Container = styled.main`
  background-color: ${colorsV2.white100};
  width: 280px;
  height: 100%;
  display: grid;
  grid-template-rows: max-content max-content auto max-content;
`;

const List = styled.div`
  padding-top: 12px;
  border-bottom: 1px solid ${colorsV2.gray20};
  border-top: 1px solid ${colorsV2.gray20};
`;

const CloseHeader = styled.div`
  padding: 12px 0 12px 16px;
  width: max-content;
`;

const ActionButton = styled.div`
  padding: 20px 16px;
  display: flex;
  ${horizontalSpace(16)};

  &.footer {
    justify-content: space-between;
  }
`;

interface GrammarMenuHeaderProps {
  menus: any;
  onClose?: () => void;
  onNavUserPage?: () => void;
  onSignOut?: () => void;
  otherUrlInfo?: any;
}

export const GrammarMenuHeader = observer((props: GrammarMenuHeaderProps) => {
  const { menus, onClose, onNavUserPage, otherUrlInfo } = props;
  const { onUserLogin, onUserSignUp } = useAuthorizeUser();
  const { onUserLogOut } = useLogOutUser();
  const { isLoggedIn } = useGetAuthInfo();
  const rawExerciseUrl = findGrammarUrlByTemplateTypeId(otherUrlInfo, GrammarTemplateTypeEnum.LANDING_GRAMMAR_EXERCISE);
  const rawTheoryUrl = findGrammarUrlByTemplateTypeId(otherUrlInfo, GrammarTemplateTypeEnum.LANDING_GRAMMAR_THEORY);

  return (
    <Container>
      <CloseHeader>
        <ButtonIconPrimaryStyles
          color="white"
          buttonStyle="outline"
          content={
            <ButtonIconPrimarySize size="x-large" icon={<IconSystemV2EssentialClose/>}/>
          }
          onClick={onClose}
        />
      </CloseHeader>
      <ActionButton>
        {isLoggedIn ?
          <UserAccountCardGrammar onClick={onNavUserPage}/> :
          <>
            <GrammarSignUpButton
              name="Đăng ký"
              onClick={onUserSignUp}
            />
            <GrammarSignInButton
              name="Đăng nhập"
              onClick={onUserLogin}
            />
          </>
        }
      </ActionButton>
      <List>
        {menus.map((item) => {
          const { pageType, url, title } = item;
          if (pageType === GrammarTemplateTypeEnum.LANDING_GRAMMAR_THEORY) {
            return (
              <GrammarMenuLink url={getFinalUrlGrammar(rawTheoryUrl)} title={title} disabled={!rawTheoryUrl}/>
            );
          }
          if (pageType === GrammarTemplateTypeEnum.LANDING_GRAMMAR_EXERCISE) {
            return (
              <GrammarMenuLink url={getFinalUrlGrammar(rawExerciseUrl)} title={title} disabled={!rawExerciseUrl}/>
            );
          }
          return <GrammarMenuLink url={url} title={title} disabled={!url}/>;
        })}
      </List>
      {isLoggedIn &&
        <ActionButton className="footer" onClick={onUserLogOut}>
          <ResponsiveTypography.Paragraph variant="medium/14-20" color="gray180">
            Đăng xuất
          </ResponsiveTypography.Paragraph>
          <IconSystemV2ArrowsExit size={20} color="gray100"/>
        </ActionButton>
      }
    </Container>
  );
});
