export const GrammarTemplateTypeEnum = {
  LANDING_HOME: 'LANDING_HOME', // Trang chủ
  LANDING_GRAMMAR_THEORY: 'LANDING_GRAMMAR_THEORY', // Lý thuyết ngữ pháp
  LANDING_GRAMMAR_EXERCISE: 'LANDING_GRAMMAR_EXERCISE', // Bài tập ngữ pháp
  BLOG_GRAMMAR_THEORY: 'BLOG_GRAMMAR_THEORY', // 1 trang blog ly thuyet ngu phap
  BLOG_GRAMMAR_EXERCISE: 'BLOG_GRAMMAR_EXERCISE', // 1 trang blog bai tap ngu phap
  BLOG_GRAMMAR_AUTHOR: 'BLOG_GRAMMAR_AUTHOR',
};

export type GrammarTemplateTypeEnum =
  (typeof GrammarTemplateTypeEnum)[keyof typeof GrammarTemplateTypeEnum];

export const GrammarTemplateTypeList = [
  GrammarTemplateTypeEnum.LANDING_HOME,
  GrammarTemplateTypeEnum.LANDING_GRAMMAR_THEORY,
  GrammarTemplateTypeEnum.LANDING_GRAMMAR_EXERCISE,
];

export const ReadDoStatusEnum = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
};

export type ReadDoStatusEnum =
  (typeof ReadDoStatusEnum)[keyof typeof ReadDoStatusEnum];

export const ExerciseProgressTypeEnum = {
  SHORT_ANSWER: 'SHORT_ANSWER',
  GAP_FILL: 'GAP_FILL',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  SUMMARY_COMPLETION_WITH_HINT: 'SUMMARY_COMPLETION_WITH_HINT',
  QUIZ: 'QUIZ',
};

export type ExerciseProgressTypeEnum =
  (typeof ExerciseProgressTypeEnum)[keyof typeof ExerciseProgressTypeEnum];

export interface GrammarUserInExerciseTestProps {
  status?: ReadDoStatusEnum,
  pageId?: string,
  timeReadInSeconds?: number,
  completedDate?: string,
  userInfo?: null,
  progress?: {
    type?: ExerciseProgressTypeEnum,
    userAnswers?: [
      {
        answerIds?: string[],
        answerId?: string,
        answer?: string,
        questionId?: string,
      }
    ]
  }
}

export interface GrammarUserInTheoryTestProps {
  status?: ReadDoStatusEnum,
  pageId?: string,
  timeReadInSeconds?: number,
  lastModifiedAt?: string,
  userInfo?: null,
}


