import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

const MainContent = styled.div`
  display: contents;
  pointer-events: auto;
`;

const ScrollContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: grid;
  align-items: center;
  overflow-y: auto;
  pointer-events: none;

  padding: 32px 16px;
`;

const Container = styled.div<{ $bgColor: string }>`
  width: 100%;
  height: 100%;

  #scroll-content__overlay {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${(p) => p.$bgColor || 'rgba(36, 41, 56, 0.45)'};
  }
`;

interface ModalPortalLayoutCenterScrollableProps {
  className?: string;
  children?: React.ReactNode;
  bgColor?: string;
  onOverlayClick?: () => void;
}

export const ModalPortalLayoutCenterScrollable = (
  props: ModalPortalLayoutCenterScrollableProps
) => {
  const { className, children, bgColor, onOverlayClick } = props;

  return (
    <Container
      $bgColor={bgColor}
      className={cl('modal-portal-layout-center-scrollable', className)}
      onClick={onOverlayClick}
    >
      <div id="scroll-content__overlay" />
      <ScrollContent className="scroll-content">
        <MainContent onClick={(e) => e.stopPropagation()}>{children}</MainContent>
      </ScrollContent>
    </Container>
  );
};
