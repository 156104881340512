import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import { useGrammarMenu } from '../../hooks/useGrammarMenu';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import cl from 'classnames';
import { GrammarTemplateTypeEnum } from '../../../shared/constants';
import { findGrammarUrlByTemplateTypeId, getFinalUrlGrammar } from '../../../shared/utils';

const NavItem = styled.div`
  cursor: pointer;
  display: flex;
  height: 64px;
  width: max-content;
  border-bottom: 2px solid transparent;

  &:hover,
  &.selected,
  &.active {
    border-bottom: 2px solid ${colorsV2.purple100};
  }

  &.disabled {
    color: ${colorsV2.gray100};
    cursor: not-allowed;
  }
`;

const Main = styled.nav`
  display: grid;
  grid-auto-flow: column;
  gap: 40px;
  align-items: center;
  grid-template-columns: max-content;
  height: 100%;

  .nav-item {
    position: relative;
    place-items: center;
  }
`;

const NavLink = styled.a`
  &.disabled {
    color: ${colorsV2.gray100};
    cursor: not-allowed;
  }
`;

const BastNavItem = (props: any) => {
  const { id, selected, title } = props;
  return (
    <NavItem key={id} className={cl('nav-item', { selected })}>
      <ResponsiveTypography.Paragraph variant="semi-bold/14-20" color="black100">
        {title}
      </ResponsiveTypography.Paragraph>
    </NavItem>
  );
};
export const GrammarNavigation = observer((props: any) => {
  const { otherUrlInfo, pageType: currentPageType } = props;
  const { topBarMenu } = useGrammarMenu();
  const rawExerciseUrl = findGrammarUrlByTemplateTypeId(
    otherUrlInfo,
    GrammarTemplateTypeEnum.LANDING_GRAMMAR_EXERCISE
  );
  const rawTheoryUrl = findGrammarUrlByTemplateTypeId(
    otherUrlInfo,
    GrammarTemplateTypeEnum.LANDING_GRAMMAR_THEORY
  );

  return (
    <Main id="topbar-nav-list">
      {topBarMenu.map((nav, navIndex) => {
        const { id, pageType, title } = nav as any;

        if (pageType === GrammarTemplateTypeEnum?.LANDING_GRAMMAR_THEORY) {
          if (currentPageType === GrammarTemplateTypeEnum?.LANDING_GRAMMAR_THEORY) {
            return (
              <BastNavItem
                key={id}
                id={id}
                title={title}
                selected={currentPageType === GrammarTemplateTypeEnum?.LANDING_GRAMMAR_THEORY}
              />
            );
          }
          return (
            <NavLink key={id} className={cl('nav-item')} href={getFinalUrlGrammar(rawTheoryUrl)}>
              <BastNavItem
                id={id}
                title={title}
                selected={currentPageType === GrammarTemplateTypeEnum?.LANDING_GRAMMAR_THEORY}
              />
            </NavLink>
          );
        }
        if (pageType === GrammarTemplateTypeEnum?.LANDING_GRAMMAR_EXERCISE) {
          if (currentPageType === GrammarTemplateTypeEnum?.LANDING_GRAMMAR_EXERCISE) {
            return (
              <BastNavItem
                key={id}
                id={id}
                title={title}
                selected={currentPageType === GrammarTemplateTypeEnum?.LANDING_GRAMMAR_EXERCISE}
              />
            );
          }
          return (
            <NavLink key={id} className={cl('nav-item')} href={getFinalUrlGrammar(rawExerciseUrl)}>
              <BastNavItem
                id={id}
                title={title}
                selected={currentPageType === GrammarTemplateTypeEnum?.LANDING_GRAMMAR_EXERCISE}
              />
            </NavLink>
          );
        }

        return <BastNavItem key={id} id={id} title={title} />;
      })}
    </Main>
  );
});
