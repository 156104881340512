import * as React from 'react';
import { useMethods } from 'react-use';
import { isEqual } from 'lodash';

interface FilterModel {
  search?: string;
}

const createMethods = (prevState: FilterModel) => {
  return {
    setSearch(search) {
      return {
        ...prevState,
        search,
      };
    },
  };
};

export const useLandingGrammarFilter = () => {
  const [filterState, methods] = useMethods(createMethods, {});

  const isFiltered = React.useMemo(() => {
    return !isEqual(filterState, {});
  }, [filterState]);

  return {
    filter: {
      ...filterState,
      ...methods,
    },
    filterQuery: filterState,
    isFiltered,
  };
};
