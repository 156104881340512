import * as React from 'react';
import { createAvatarComponent, ValueSource } from 'react-avatar';

const Avatar = createAvatarComponent({
  sources: [ValueSource],
});

interface DolAvatarImgProps extends React.HTMLAttributes<HTMLDivElement> {
  alt?: string;
  url: any;
  round?: boolean;
  size?: any;
  name?: string;

  textSizeRatio?: number;
}

export const DolAvatarImg = (props: DolAvatarImgProps) => {
  const { name, alt, url, round = true, size, style, className, ...rest } = props;
  const [isError, setIsError] = React.useState(false);
  const genStyles = React.useMemo(() => {
    const output: any = {};
    if (round) {
      output.borderRadius = '50%';
    }
    if (size) {
      output.width = size;
      output.height = size;
    }
    return { ...output, ...style };
  }, [round, size, style]);

  const renderAvatar = React.useCallback(() => {
    const imageUrl = url?.url || url;
    if (isError || !imageUrl) {
      return (
        <Avatar
          size={size}
          src={imageUrl}
          name={alt || name}
          textSizeRatio={1.75}
          style={style}
          round={round}
          className={className}
          {...rest}
        />
      );
    }
    return (
      <img
        referrerPolicy="no-referrer"
        style={genStyles}
        src={imageUrl}
        alt={alt || name}
        onError={() => {
          setIsError(true);
        }}
        className={className}
      />
    );
  }, [url, isError, genStyles, alt, name, className, size, style, round, rest]);

  return renderAvatar();
};
