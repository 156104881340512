import * as React from 'react';
import { ButtonIconPrimarySize } from '@doltech/ui/lib/figma/Button/ButtonIconPrimarySize/ButtonIconPrimarySize';
import { ButtonIconPrimaryStyles } from '@doltech/ui/lib/figma/Button/ButtonIconPrimaryStyles/ButtonIconPrimaryStyles';
import { IconSystemV2ContentMenuCenter } from '@doltech/icons/SystemV2/IconSystemV2ContentMenuCenter';

interface BtnSidebarProps {
  onOpen: () => void;
}

export const BtnHamburgerSidebar = (props: BtnSidebarProps) => {
  const { onOpen } = props;

  return (
    <ButtonIconPrimaryStyles
      color="white"
      buttonStyle="outline"
      content={
        <ButtonIconPrimarySize size="x-large" icon={<IconSystemV2ContentMenuCenter/>}/>
      }
      onClick={onOpen}
    />
  );
};
