/** GRAMMAR TYPE ENUM **/
export const GrammarTopicTypeEnum = {
  CAC_THI_TRONG_TIENG_ANH: 'TENSES',
  DANH_TU: 'NOUN',
  DAI_TU: 'PRONOUN',
  DONG_TU: 'VERB',
  TRANG_TU: 'ADVERB',
  TINH_TU: 'ADJECTIVE',
  LIEN_TU: 'CONJUNCTION',
  GIOI_TU: 'PREPOSITION',
  THAN_TU: 'INTERJECTION',
  MAO_TU: 'ARTICLE',
  CAU_TRUC_CAU: 'SENTENCE',
  MENH_DE: 'CLAUSE',
  TU_HAN_DINH: 'DETERMINER',
  OTHER: 'OTHER',
} as const;

export type GrammarTopicTypeEnum =
  (typeof GrammarTopicTypeEnum)[keyof typeof GrammarTopicTypeEnum];

export const StatusTypeEnum = {
  EDITING: 'EDITING',
  DRAFT: 'DRAFT',
  PUBLISH: 'PUBLISHED',
} as const;

export type StatusTypeEnum =
  (typeof StatusTypeEnum)[keyof typeof StatusTypeEnum];

export const PublishedForTypeEnum = {
  FOR_ALL: 'ALL',
  NOBODY: 'NOBODY',
} as const;

export type PublishedForTypeEnum =
  (typeof PublishedForTypeEnum)[keyof typeof PublishedForTypeEnum];

export const GrammarErrorTypeEnum = {
  TOPIC_DUPLICATE: 'TOPIC_DUPLICATE',
  URL_DUPLICATE: 'URL_DUPLICATE',
  NAME_DUPLICATE: 'NAME_DUPLICATE',
  ERROR_TIME_INPUT: 'ERROR_TIME_INPUT',
  RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
} as const;

export type GrammarErrorTypeEnum =
  (typeof GrammarErrorTypeEnum)[keyof typeof GrammarErrorTypeEnum];

// listing status
export const ListingStatusTypeEnum = {
  LISTED: 'LISTED',
  UNLISTED: 'UN_LISTED',
  NOT_LISTED_YET: 'NOT_LISTED_YET',
} as const;

export type ListingStatusTypeEnum =
  (typeof ListingStatusTypeEnum)[keyof typeof ListingStatusTypeEnum];


export const GRAMMAR_SECTION_PREFIX = 'section-';

/** FILTER OPTION **/
export const GrammarTopicSelectOption = [
  { label: 'All', value: undefined },
  { label: 'Thì trong tiếng Anh', value: GrammarTopicTypeEnum.CAC_THI_TRONG_TIENG_ANH },
  { label: 'Danh từ', value: GrammarTopicTypeEnum.DANH_TU },
  { label: 'Đại từ', value: GrammarTopicTypeEnum.DAI_TU },
  { label: 'Động từ', value: GrammarTopicTypeEnum.DONG_TU },
  { label: 'Trạng từ', value: GrammarTopicTypeEnum.TRANG_TU },
  { label: 'Tính từ', value: GrammarTopicTypeEnum.TINH_TU },
  { label: 'Liên từ', value: GrammarTopicTypeEnum.LIEN_TU },
  { label: 'Giới từ', value: GrammarTopicTypeEnum.GIOI_TU },
  { label: 'Thán từ', value: GrammarTopicTypeEnum.THAN_TU },
  { label: 'Mạo từ', value: GrammarTopicTypeEnum.MAO_TU },
  { label: 'Cấu trúc câu', value: GrammarTopicTypeEnum.CAU_TRUC_CAU },
  { label: 'Mệnh đề', value: GrammarTopicTypeEnum.MENH_DE },
  { label: 'Từ hạn định', value: GrammarTopicTypeEnum.TU_HAN_DINH },
  { label: 'Điểm ngữ pháp khác', value: GrammarTopicTypeEnum.OTHER },
];

export const GrammarTopicSelectOptionsNoAll = [
  { label: 'Thì trong tiếng Anh', value: GrammarTopicTypeEnum.CAC_THI_TRONG_TIENG_ANH },
  { label: 'Danh từ', value: GrammarTopicTypeEnum.DANH_TU },
  { label: 'Đại từ', value: GrammarTopicTypeEnum.DAI_TU },
  { label: 'Động từ', value: GrammarTopicTypeEnum.DONG_TU },
  { label: 'Trạng từ', value: GrammarTopicTypeEnum.TRANG_TU },
  { label: 'Tính từ', value: GrammarTopicTypeEnum.TINH_TU },
  { label: 'Liên từ', value: GrammarTopicTypeEnum.LIEN_TU },
  { label: 'Giới từ', value: GrammarTopicTypeEnum.GIOI_TU },
  { label: 'Thán từ', value: GrammarTopicTypeEnum.THAN_TU },
  { label: 'Mạo từ', value: GrammarTopicTypeEnum.MAO_TU },
  { label: 'Cấu trúc câu', value: GrammarTopicTypeEnum.CAU_TRUC_CAU },
  { label: 'Mệnh đề', value: GrammarTopicTypeEnum.MENH_DE },
  { label: 'Từ hạn định', value: GrammarTopicTypeEnum.TU_HAN_DINH },
  { label: 'Điểm ngữ pháp khác', value: GrammarTopicTypeEnum.OTHER },
];
export const StatusTypeSelectOption = [
  { label: 'All', value: undefined },
  { label: 'Editing', value: StatusTypeEnum.EDITING },
  { label: 'Draft', value: StatusTypeEnum.DRAFT },
  { label: 'Publish', value: StatusTypeEnum.PUBLISH },
];

export const PublishForTypeSelectOption = [
  { label: 'All', value: undefined },
  { label: 'Nobody', value: PublishedForTypeEnum.NOBODY },
  { label: 'For all', value: PublishedForTypeEnum.FOR_ALL },
];

export const ListingTypeSelectOption = [
  { label: 'All', value: undefined },
  { label: 'Listed', value: ListingStatusTypeEnum.LISTED },
  { label: 'Unlisted', value: ListingStatusTypeEnum.UNLISTED },
  { label: 'Not listed yet', value: ListingStatusTypeEnum.NOT_LISTED_YET },
];

/** CONTENT_BY_ENUM **/
// topic
export const GrammarTopicTypeContent = {
  [GrammarTopicTypeEnum.CAC_THI_TRONG_TIENG_ANH]: 'Thì trong tiếng Anh',
  [GrammarTopicTypeEnum.DANH_TU]: 'Danh từ',
  [GrammarTopicTypeEnum.DAI_TU]: 'Đại từ',
  [GrammarTopicTypeEnum.DONG_TU]: 'Động từ',
  [GrammarTopicTypeEnum.TRANG_TU]: 'Trạng từ',
  [GrammarTopicTypeEnum.TINH_TU]: 'Tính từ',
  [GrammarTopicTypeEnum.LIEN_TU]: 'Liên từ',
  [GrammarTopicTypeEnum.GIOI_TU]: 'Giới từ',
  [GrammarTopicTypeEnum.THAN_TU]: 'Thán từ',
  [GrammarTopicTypeEnum.MAO_TU]: 'Mạo từ',
  [GrammarTopicTypeEnum.CAU_TRUC_CAU]: 'Cấu trúc câu',
  [GrammarTopicTypeEnum.MENH_DE]: 'Mệnh đề',
  [GrammarTopicTypeEnum.TU_HAN_DINH]: 'Từ hạn định',
  [GrammarTopicTypeEnum.OTHER]: 'Điểm ngữ pháp khác',
};

export const GrammarUrlByTopicType = {
  [GrammarTopicTypeEnum.CAC_THI_TRONG_TIENG_ANH]: 'thi-trong-tieng-anh',
  [GrammarTopicTypeEnum.DANH_TU]: 'danh-tu',
  [GrammarTopicTypeEnum.DAI_TU]: 'dai-tu',
  [GrammarTopicTypeEnum.DONG_TU]: 'dong-tu',
  [GrammarTopicTypeEnum.TRANG_TU]: 'trang-tu',
  [GrammarTopicTypeEnum.TINH_TU]: 'tinh-tu',
  [GrammarTopicTypeEnum.LIEN_TU]: 'lien-tu',
  [GrammarTopicTypeEnum.GIOI_TU]: 'gioi-tu',
  [GrammarTopicTypeEnum.THAN_TU]: 'than-tu',
  [GrammarTopicTypeEnum.MAO_TU]: 'mao-tu',
  [GrammarTopicTypeEnum.CAU_TRUC_CAU]: 'cau-truc-cau',
  [GrammarTopicTypeEnum.MENH_DE]: 'menh-de',
  [GrammarTopicTypeEnum.TU_HAN_DINH]: 'tu-han-dinh',
  [GrammarTopicTypeEnum.OTHER]: 'diem-ngu-phap-khac',
};

export const GrammarTopicTypeByUrl = {
  'thi-trong-tieng-anh': GrammarTopicTypeEnum.CAC_THI_TRONG_TIENG_ANH,
  'danh-tu': GrammarTopicTypeEnum.DANH_TU,
  'dai-tu': GrammarTopicTypeEnum.DAI_TU,
  'dong-tu': GrammarTopicTypeEnum.DONG_TU,
  'trang-tu': GrammarTopicTypeEnum.TRANG_TU,
  'tinh-tu': GrammarTopicTypeEnum.TINH_TU,
  'lien-tu': GrammarTopicTypeEnum.LIEN_TU,
  'gioi-tu': GrammarTopicTypeEnum.GIOI_TU,
  'than-tu': GrammarTopicTypeEnum.THAN_TU,
  'mao-tu': GrammarTopicTypeEnum.MAO_TU,
  'cau-truc-cau': GrammarTopicTypeEnum.CAU_TRUC_CAU,
  'menh-de': GrammarTopicTypeEnum.MENH_DE,
  'tu-han-dinh': GrammarTopicTypeEnum.TU_HAN_DINH,
  'diem-ngu-phap-khac': GrammarTopicTypeEnum.OTHER,
};

export const GrammarTemplateTypeEnum = {
  LANDING_HOME: 'LANDING_HOME', // Trang chủ
  LANDING_GRAMMAR_THEORY: 'LANDING_GRAMMAR_THEORY', // Lý thuyết ngữ pháp
  LANDING_GRAMMAR_EXERCISE: 'LANDING_GRAMMAR_EXERCISE', // Bài tập ngữ pháp
  BLOG_GRAMMAR_THEORY: 'BLOG_GRAMMAR_THEORY', // 1 trang blog ly thuyet ngu phap
  BLOG_GRAMMAR_EXERCISE: 'BLOG_GRAMMAR_EXERCISE', // 1 trang blog bai tap ngu phap
  BLOG_GRAMMAR_AUTHOR: 'BLOG_GRAMMAR_AUTHOR',
};

export type GrammarTemplateTypeEnum =
  (typeof GrammarTemplateTypeEnum)[keyof typeof GrammarTemplateTypeEnum];
