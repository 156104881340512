import { css } from 'styled-components';
import { colorsV2 } from '../../colors-v2';

const colorWhite = css`
  &.color-white {
    &.background-light {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.gray160};

        &:hover {
          background-color: ${colorsV2.gray10};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.gray20};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.gray160};

        &:hover {
          background-color: ${colorsV2.gray20};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.gray40};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }
    &.background-dark {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.white10};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.white20};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorGray = css`
  &.color-gray {
    &.background-light {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.gray160};

        &:hover {
          background-color: ${colorsV2.gray10};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.gray20};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.gray160};

        &:hover {
          background-color: ${colorsV2.gray20};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.gray40};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }
    &.background-dark {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.bt20};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.bt40};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorBlue = css`
  &.color-blue {
    &.background-light {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.blue100};

        &:hover {
          background-color: ${colorsV2.blue5};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.blue10};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.blue100};

        &:hover {
          background-color: ${colorsV2.blue10};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.blue20};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }
  }

  &.background-dark {
    &.color-style-bold {
      background-color: transparent;
      color: ${colorsV2.blue100};

      &:hover {
        background-color: ${colorsV2.blue140};
        color: ${colorsV2.white100};
      }

      &.active,
        &:active {
        background-color: ${colorsV2.blue160};
        color: ${colorsV2.white100};
      }

      &.disabled {
        &.disabled {
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorBlack = css`
  &.color-black {
    &.background-light {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.gray180};

        &:hover {
          background-color: ${colorsV2.gray180};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.gray160};
          color: ${colorsV2.white100};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.gray180};

        &:hover {
          background-color: ${colorsV2.gray180};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.gray160};
          color: ${colorsV2.white100};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.white100};

        &:hover {
          background-color: ${colorsV2.bt30};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.bt50};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorPrimary = css`
  &.color-primary {
    &.background-light {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.primary100};

        &:hover {
          background-color: ${colorsV2.primary5};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.primary10};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.primary100};

        &:hover {
          background-color: ${colorsV2.primary10};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.primary20};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.primary100};

        &:hover {
          background-color: ${colorsV2.primary140};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.primary160};
          color: ${colorsV2.white100};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorPurple = css`
  &.color-purple {
    &.background-light {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.purple100};

        &:hover {
          background-color: ${colorsV2.purple5};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.purple10};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.purple100};

        &:hover {
          background-color: ${colorsV2.purple10};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.purple20};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.gray40};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        background-color: transparent;
        color: ${colorsV2.purple100};

        &:hover {
          background-color: ${colorsV2.purple140};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          background-color: ${colorsV2.purple160};
          color: ${colorsV2.white100};
        }

        &.disabled {
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;


export const transparentFilledStyles = css`
  &.transparent {
    &.filled {
      ${colorPrimary}
      ${colorBlue}
      ${colorBlack}
      ${colorWhite}
      ${colorGray}
      ${colorPurple}
    }
  }
`;
