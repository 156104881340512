import * as React from 'react';
import styled from 'styled-components';
import { GrammarLandingPageHeaderLayout } from './GrammarLandingPageHeaderLayout';
import { GrammarLandingPageFooterLayout } from './GrammarLandingPageFooterLayout';
import { SpokePageSpinner } from '@doltech/core/lib/auth/SpokePageSpinner';
import { DynamicPageProps } from '@doltech/routeDefinition';
import { LandingPromotionFreeTestBanner } from '@doltech/ui/lib/shared/templates/LandingBlog/PromotionTypeForLandingPage/LandingPromotionFreeTestBanner';

const Main = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-rows: max-content;
  width: 100%;

  .grammar-body {
    width: 100%;
  }
`;

interface GrammarLandingPageLayoutProps
  extends React.HTMLAttributes<HTMLDivElement>,
    DynamicPageProps {
  loading?: boolean;
  otherUrlInfo?: any;
  hasPromotion?: boolean;
}

export const GrammarLandingPageLayout = (props: GrammarLandingPageLayoutProps) => {
  const { children, loading, hasPromotion } = props;

  return (
    <Main>
      <GrammarLandingPageHeaderLayout {...props} />
      {loading ? <SpokePageSpinner /> : <main className="grammar-body">{children}</main>}
      {hasPromotion && (
        <aside>
          <LandingPromotionFreeTestBanner />
        </aside>
      )}
      <GrammarLandingPageFooterLayout />
    </Main>
  );
};
