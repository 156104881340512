import * as React from 'react';
import styled from 'styled-components';
import { UserCardGeneral } from '@doltech/ui/lib/course/components/UserCard/General/UserCardGeneral';
import {
  IconSystemV2ArrowChevronRightArrowRight,
} from '@doltech/icons/SystemV2/IconSystemV2ArrowChevronRightArrowRight';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: ${colorsV2.gray10};
    cursor: pointer;
  }
`;


interface UserAccountCardGrammarProps {
  userInfo?: any;
  onClick?: () => void;
}

export const UserAccountCardGrammar = (props: UserAccountCardGrammarProps) => {
  const { onClick } = props;
  const { userInfo } = useGetAuthInfo();

  return (
    <Container className="grammar-sign-in-button" onClick={onClick}>
      <UserCardGeneral
        name={userInfo?.displayName}
        avatarUrl={userInfo?.photoURL}
        subText={userInfo?.email}
      />
      <div onClick={onClick} className="button">
        <IconSystemV2ArrowChevronRightArrowRight size={20} color="gray100"/>
      </div>
    </Container>
  );
};
