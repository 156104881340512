import * as React from 'react';
import styled from 'styled-components';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { useAuthorizeUser } from '../../../home-page/hooks/useAuthorizeUser';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import dynamic from 'next/dynamic';
import { GrammarSignUpButton } from './GrammarSignUpButton';
import { observer } from 'mobx-react-lite';


const AvatarLoading = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: ${colorsV2.gray20};
`;

const GrammarSignUpLoading = styled.div`
  background-color: ${colorsV2.gray10};
  width: 83px;
  height: 40px;
  border-radius: 8px;
`;

const HeaderActionGrammarSignUpPopover = dynamic(
  () => import('./HeaderActionGrammarSignUpPopover').then((mod) => mod.HeaderActionGrammarSignUpPopover),
  {
    ssr: false,
    loading: () => <AvatarLoading/>,
  },
);

interface GrammarFreeUserSignUpButtonProps {
}

export const GrammarFreeUserSignUpButton = observer((props: GrammarFreeUserSignUpButtonProps) => {
  const { isLoggedIn, isLoading } = useGetAuthInfo();
  const { onUserSignUp } = useAuthorizeUser();

  if (isLoading) {
    return <GrammarSignUpLoading/>;
  }
  if (isLoggedIn) {
    return <HeaderActionGrammarSignUpPopover/>;
  }
  return (
    <GrammarSignUpButton
      name="Đăng ký"
      onClick={onUserSignUp}
      disabled={isLoggedIn || isLoading}
    />
  );
});
